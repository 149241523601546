<template>
  <b-modal
      id="scb-easy-app-modal"
      hide-footer
      size="xl"
      title="ลงทะเบียนระบบออโต้ SCB Easy App"
  >
    <b-overlay :show="isFetching">
      <ValidationObserver v-if="step === 1" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(sendOtp)">
        <ValidationProvider
            v-slot="{ errors }"
            name="identification"
            rules="required|length:13|numeric"
        >
          <b-form-group label="เลขบัตรประจำตัวประชาชน">
            <b-form-input
                v-model="registerForm.identification"
                :aria-invalid="false"
                :state="errors[0] ? false : null"
                maxlength="13"
                placeholder="เลขบัตรประจำตัวประชาชน"
                type="text"
            ></b-form-input>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <b-row class="mt-4">
          <b-col md="4">
            <ValidationProvider v-slot="{ errors }" name="day" rules="required|length:2|numeric">
              <b-form-group label="วัน">
                <b-form-input
                    v-model="registerForm.day"
                    :aria-invalid="false"
                    :state="errors[0] ? false : null"
                    maxlength="2"
                    placeholder="01 เลขวัน 2 หลัก"
                    type="text"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="4">
            <ValidationProvider v-slot="{ errors }" name="month" rules="required|length:2|numeric">
              <b-form-group label="เดือน">
                <b-form-input
                    v-model="registerForm.month"
                    :aria-invalid="false"
                    :state="errors[0] ? false : null"
                    maxlength="2"
                    placeholder="01 เลขเดือน 2 หลัก"
                    type="text"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="4">
            <ValidationProvider v-slot="{ errors }" name="year" rules="required|length:4|numeric">
              <b-form-group label="ปีเกิด (ค.ศ.) ">
                <b-form-input
                    v-model="registerForm.year"
                    :aria-invalid="false"
                    :state="errors[0] ? false : null"
                    maxlength="4"
                    placeholder="2001 ปี ค.ศ."
                    type="text"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <ValidationProvider v-slot="{ errors }" name="pin" rules="required|length:6|numeric">
              <b-form-group label="พิน 6 หลัก">
                <b-form-input
                    v-model="registerForm.pin"
                    :aria-invalid="false"
                    :state="errors[0] ? false : null"
                    maxlength="6"
                    placeholder="123456 PIN"
                    type="text"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col>
            <ValidationProvider
                v-slot="{ errors }"
                name="mobile_phone_no"
                rules="required|length:10|numeric"
            >
              <b-form-group label="เบอร์โทรศัพท์">
                <b-form-input
                    v-model="registerForm.mobilePhoneNo"
                    :aria-invalid="false"
                    :state="errors[0] ? false : null"
                    maxlength="10"
                    placeholder="08XXXXXXXX เบอร์โทร"
                    type="text"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <div class="d-flex">
          <b-button :disabled="isFetching" class="ml-auto" type="submit" variant="primary">
            ส่ง OTP
          </b-button>
        </div>
      </form>
    </ValidationObserver>
      <ValidationObserver v-else v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(verifyOtp)">
        <ValidationProvider v-slot="{ errors }" name="otp" rules="required">
          <b-form-group label="OTP">
            <b-form-input
                v-model="otpForm.otp"
                :aria-invalid="false"
                :disabled="isFetching"
                :state="errors[0] ? false : null"
                maxlength="6"
                min="6"
                placeholder="OTP"
                type="text"
            ></b-form-input>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <b-button type="submit" variant="success">{{
            $t('buttons.submit')
          }}</b-button>
        <b-button :disabled="isFetching" class="ml-2" type="button" variant="outline-primary" @click="(e) => sendOtp()">
          {{ $t('buttons.resend') }}
        </b-button>
        <b-button class="ml-2" type="button" variant="light" @click="step = 1">
          แก้ไขข้อมูล
        </b-button>
      </b-form>
    </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import Vue from 'vue';
import {mapActions} from 'vuex';

export default Vue.extend({
  name: 'ScbAutoEasyAppModal',
  props: {
    agentBankAccountId: {
      type: String,
      required: true
    },
    scbAccount: {
      type: Object,
      default: () => ({
        identification: '',
        year: '',
        month: '',
        day: '',
        pin: '',
        mobilePhoneNo: '',
      })
    }
  },
  data() {
    return {
      step: 1,
      registerForm: {},
      otpForm: {
        otp: '',
        auth: '',
        tokenUUID: '',
        tag: '',
        deviceId: ''
      },
      isFetching: false
    }
  },
  created() {
    this.registerForm = {
      identification: this.scbAccount?.identification || '',
      year: this.scbAccount?.year || '',
      month: this.scbAccount?.month || '',
      day: this.scbAccount?.day || '',
      pin: this.scbAccount?.pin || '',
      mobilePhoneNo: this.scbAccount?.mobilePhoneNo || '',
    }
  },
  methods: {
    ...mapActions(['fetchBankAccount', 'registerSCB', 'verifyScbOTP']),
    async sendOtp() {
      this.isFetching = true
      const response = await this.registerSCB({ id: this.agentBankAccountId, data: this.registerForm })
      if(response) {
        if(response.skipOtp) {
          this.$bvModal.hide('scb-easy-app-modal')
        } else {
          this.step = 2
          this.otpForm.auth = response.auth
          this.otpForm.tokenUUID = response.tokenUUID
          this.otpForm.tag = response.tag
          this.otpForm.deviceId = response.deviceId
        }
      }
      this.isFetching = false
    },
    async verifyOtp() {
      this.isFetching = true
      const response = await this.verifyScbOTP({
        id: this.agentBankAccountId,
        data: {
          ...this.registerForm,
          ...this.otpForm,
        }
      })
      if(response) {
        this.step = 1
        this.$bvModal.hide('scb-easy-app-modal')
        await this.fetchBankAccount(this.agentBankAccountId)
      }
      this.isFetching = false
    }
  }
})
</script>

<style scoped>

</style>